<template>
  <div class="visualization-wrap visualization"
    ref="visHeight">
    <iframe :src="pluginSrc" frameborder="0" class="iframe"></iframe>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Visualization',

  props: {
    config: {
      type: Object,
    },
  },

  methods: {
    ...mapActions({
      setMobileVisualizationHeight: 'betslip/setMobileVisualizationHeight',
    }),
    createSrc(data) {
      const { company, channel } = data;
      const env = process.env.NODE_ENV;
      const host = process.env.VUE_APP_VISUALIZATION_HOST;

      return `${host}/?company=${company}&channel=${channel}&locale=${this.config.locale}&platformDataCenter=${this.getDc()}&env=${env}&appMode=standard-iframe&server=${this.services_api}&deliveryChannel=${this.config.applicationName}`;
    },
    getDc() {
      if (this.config.ui.config.platformDataCenter) {
        return this.config.ui.config.platformDataCenter;
      }
      return 'en';
    },
    onFullScreenChange() {
      setTimeout(() => {
        this.setMobileVisualizationHeight(this.$refs.visHeight.clientHeight);
      }, 1000);
    },
  },
  computed: {
    ...mapGetters([
      'services_api',
    ]),
    pluginSrc() {
      return this.createSrc(this.config);
    },
  },
  mounted() {
    this.setMobileVisualizationHeight(this.$refs.visHeight.clientHeight);
    document.addEventListener('fullscreenchange', this.onFullScreenChange, false);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenChange, false);
    document.addEventListener('mozfullscreenchange', this.onFullScreenChange, false);
  },
};
</script>

<style scoped lang="scss">
.visualization-wrap {
  position: relative;
  padding-top: 56.25%;
  background: #2c2e30;
}
.iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
@media (max-width: 479px) {
  .visualization-wrap {
    &.visualization {
      padding-top: 70%;
    }
  }
}
@media (min-width: 480px) and (max-width: 640px) {
  .visualization-wrap {
    &.visualization {
      padding-top: 66.66%;
    }
  }
}
@media (min-width: 641px) {
  .visualization-wrap {
    &.visualization {
      padding-top: 56.25%;
    }
  }
}
</style>
