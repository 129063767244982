var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "main-container" },
        [
          _c("div", { staticClass: "content-left-wrapper" }, [
            _c("div", { staticClass: "left-side-wrap" }, [
              _c(
                "div",
                { staticClass: "content-left" },
                [
                  _c(
                    "Scrollable",
                    [
                      _vm.showModal
                        ? _c("Modal", {
                            ref: "modal",
                            staticClass: "events-container",
                            attrs: {
                              fullScreen: _vm.isMob,
                              showFooter: true,
                              width: "100%",
                              height: "100%",
                            },
                            on: {
                              closeModal: _vm.closeDetails,
                              closeModalOutside: function ($event) {
                                return _vm.closeDetails("outside")
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c("div", { staticClass: "info" }, [
                                        _c(
                                          "div",
                                          { staticClass: "product-info" },
                                          [_vm._v(_vm._s(_vm.productName))]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "round-info" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.roundLabel) +
                                                  "\n                  "
                                              ),
                                            ]),
                                            _vm._v(
                                              _vm._s(_vm.currentScheduleRound)
                                            ),
                                          ]
                                        ),
                                        _c("i", {
                                          staticClass: "icon icon-close-a",
                                          on: { click: _vm.closeDetails },
                                        }),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c(
                                        "Tabs",
                                        _vm._b(
                                          {
                                            attrs: {
                                              tabs: _vm.tabComponents,
                                              activeTab: _vm.activeTab,
                                            },
                                            on: { change: _vm.updateTab },
                                          },
                                          "Tabs",
                                          _vm.$attrs,
                                          false
                                        )
                                      ),
                                      _c(
                                        _vm.component.component,
                                        _vm._b(
                                          {
                                            tag: "component",
                                            attrs: {
                                              config_bets:
                                                _vm.product_config.bets,
                                              signal:
                                                _vm.round_phase == "results",
                                              mobile: _vm.mobile,
                                            },
                                          },
                                          "component",
                                          _vm.$attrs,
                                          false
                                        )
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3480024949
                            ),
                          })
                        : _vm._e(),
                      _vm.showHelpModal
                        ? _c("Modal", {
                            ref: "modalHelp",
                            staticClass: "help-container",
                            attrs: {
                              showFooter: false,
                              width: "100%",
                              height: "100%",
                            },
                            on: {
                              closeModal: _vm.closeDetailsHelp,
                              closeModalOutside: function ($event) {
                                return _vm.closeDetailsHelp("outside")
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function () {
                                    return [
                                      _c("div", { staticClass: "info" }, [
                                        _c(
                                          "div",
                                          { staticClass: "product-info" },
                                          [_vm._v(_vm._s(_vm.productName))]
                                        ),
                                        _c("i", {
                                          staticClass: "icon icon-close-a",
                                          on: { click: _vm.closeDetailsHelp },
                                        }),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "body",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "game-info-help-wrapper",
                                          staticStyle: { height: "400px" },
                                        },
                                        [
                                          _c("GameHelp", {
                                            attrs: {
                                              "help-page": _vm.helpPage,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2592499133
                            ),
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "scroll-content",
                          class: {
                            "full-web": !_vm.isIntegration,
                          },
                        },
                        [
                          _c("div", { staticClass: "game-content" }, [
                            _c(
                              "div",
                              { staticClass: "main spacer-h" },
                              [
                                _c("GamesHeader", {
                                  attrs: {
                                    showInfoButton:
                                      _vm.isInfoButtonActive && !_vm.isTablet,
                                    showBackButton:
                                      !_vm.isIntegration &&
                                      !(
                                        _vm.config.applicationName ===
                                          "Mobile" && _vm.isMobileDeviceIos
                                      ),
                                    showBalance: _vm.showBalance,
                                    showHelpButton: _vm.isHelpButtonActive,
                                    showStatsButton:
                                      _vm.isStatsButtonActive && _vm.isMob,
                                    showResultsButton:
                                      _vm.isResultsButtonActive && _vm.isMob,
                                  },
                                  on: { ShowInfo: _vm.toggleModal },
                                }),
                                _vm.isBoostActive && _vm.isMob && !_vm.isTablet
                                  ? _c("Boost")
                                  : _vm._e(),
                                _c("Visualization", {
                                  attrs: { config: _vm.config },
                                }),
                                _c("NotificationBar"),
                                _c(
                                  "div",
                                  { staticClass: "schedule-wrapper" },
                                  [_c("ScheduleWrapper")],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "spacer-v" },
                                  [
                                    _c("Betting", {
                                      attrs: { mobile: _vm.mobile },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            !_vm.isMob && !_vm.isTablet
                              ? _c(
                                  "div",
                                  { staticClass: "sidebar" },
                                  [
                                    _c(
                                      "Tabs",
                                      _vm._b(
                                        {
                                          attrs: {
                                            tabs: _vm.tabComponents,
                                            activeTab: _vm.activeTab,
                                          },
                                          on: { change: _vm.updateTab },
                                        },
                                        "Tabs",
                                        _vm.$attrs,
                                        false
                                      )
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "component-wrapper" },
                                      [
                                        _c(
                                          _vm.component.component,
                                          _vm._b(
                                            {
                                              tag: "component",
                                              attrs: {
                                                config_bets:
                                                  _vm.product_config.bets,
                                                signal:
                                                  _vm.round_phase == "results",
                                                mobile: _vm.mobile,
                                              },
                                            },
                                            "component",
                                            _vm.$attrs,
                                            false
                                          )
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "content-right" }, [_c("BetslipArea")], 1),
          _vm.isMob && _vm.isUserLoggedIn && !_vm.isTablet
            ? _c("FullScreen")
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }