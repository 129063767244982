<template>
  <div class="main-container" v-if="loaded">
    <div class="content-left-wrapper">
      <div class="left-side-wrap">
        <div class="content-left">
          <Scrollable>
            <Modal :fullScreen="isMob"
              :showFooter="true"
              class="events-container"
              width="100%"
              height="100%"
              v-if="showModal"
              @closeModal="closeDetails"
              @closeModalOutside="closeDetails('outside')"
              ref="modal">
              <template #header>
                <div class="info">
                  <div class="product-info">{{ productName }}</div>
                  <div class="round-info"><span>{{ roundLabel }}
                    </span>{{ currentScheduleRound }}</div>
                  <i class="icon icon-close-a"
                    @click="closeDetails" />
                </div>
              </template>
              <template #body>
                <Tabs
                  :tabs="tabComponents"
                  :activeTab="activeTab"
                  v-bind="$attrs"
                  @change="updateTab"></Tabs>
                <component v-bind:is="component.component"
                    v-bind="$attrs"
                    :config_bets="product_config.bets"
                    :signal="round_phase == 'results'"
                    :mobile="mobile"/>
              </template>
            </Modal>
            <Modal
              :showFooter="false"
              class="help-container"
              width="100%"
              height="100%"
              v-if="showHelpModal"
              @closeModal="closeDetailsHelp"
              @closeModalOutside="closeDetailsHelp('outside')"
              ref="modalHelp">
              <template #header>
                <div class="info">
                  <div class="product-info">{{ productName }}</div>
                  <i class="icon icon-close-a"
                    @click="closeDetailsHelp" />
                </div>
              </template>
              <template #body>
                <div class="game-info-help-wrapper" style="height: 400px;">
                  <GameHelp :help-page="helpPage" />
                </div>
              </template>
            </Modal>
            <div class="scroll-content"
              :class="{
                'full-web': !isIntegration}">
          <div class="game-content">
            <div class="main spacer-h">
              <GamesHeader
                :showInfoButton="isInfoButtonActive && !isTablet"
                :showBackButton="!isIntegration &&
                  !(config.applicationName === 'Mobile' && isMobileDeviceIos)"
                @ShowInfo="toggleModal"
                :showBalance="showBalance"
                :showHelpButton="isHelpButtonActive"
                :showStatsButton="isStatsButtonActive && isMob"
                :showResultsButton="isResultsButtonActive && isMob"></GamesHeader>
              <Boost v-if="isBoostActive && isMob && !isTablet" />
              <Visualization :config="config">
              </Visualization>
              <NotificationBar />
              <div class="schedule-wrapper">
                <ScheduleWrapper/>
              </div>
              <div class="spacer-v">
                <Betting :mobile="mobile">
                </Betting>
              </div>
            </div>
            <div
              v-if="!isMob && !isTablet"
              class="sidebar">
              <Tabs :tabs="tabComponents"
                :activeTab="activeTab"
                v-bind="$attrs"
                @change="updateTab"></Tabs>
              <div class="component-wrapper">
                <component v-bind:is="component.component"
                     v-bind="$attrs"
                     :config_bets="product_config.bets"
                     :signal="round_phase == 'results'"
                     :mobile="mobile"/>
              </div>
            </div>
          </div>
          </div>
          </Scrollable>
        </div>
      </div>
    </div>
    <div class="content-right">
      <BetslipArea />
    </div>
    <FullScreen v-if="isMob && isUserLoggedIn && !isTablet"/>
  </div>
</template>

<script>
import isMobile from 'ismobilejs';
import { mapActions, mapGetters } from 'vuex';
import {
  Scrollable,
  Tabs,
  GamesHeader,
  FullScreen,
  Modal,
  eventBus,
} from '@nsftx/games-sdk-js';
import Visualization from '@/components/visualization/Visualization';
import Betting from '@/components/betting/Betting';
import Statistics from '@/components/statistics/Statistics';
import Results from '@/components/results/Results';
import Boost from '@/components/Boost';
import ScheduleWrapper from '@/components/schedule/ScheduleWrapper';
import NotificationBar from '@/components/common/NotificationBar';
import BetslipArea from '@/components/betslip/BetslipArea';
import ConfigApi from '@/api/config';
import { DataService } from '@/service';
import GameHelp from '@/components/help/GameHelp';

export default {
  name: 'SevenWeb',
  components: {
    Scrollable,
    BetslipArea,
    Visualization,
    Betting,
    Statistics,
    Boost,
    Results,
    GameHelp,
    ScheduleWrapper,
    Tabs,
    NotificationBar,
    GamesHeader,
    FullScreen,
    Modal,
  },
  data() {
    return {
      tabComponents: [],
      activeTab: {},
      showModal: false,
      showInfoButton: true,
      showHelpModal: false,
      helpPage: '',
    };
  },
  methods: {
    ...mapActions([
      'disable_betting',
    ]),
    updateTab(tab) {
      this.activeTab = tab;
    },
    handleShowResults() {
      this.activeTab = {
        id: 1,
        key: 1,
        name: 'Results',
        label: this.translations.general_results,
        component: Results,
        disabled: false,
      };
    },
    handleShowStats() {
      this.activeTab = {
        id: 0,
        key: 0,
        name: 'Statistics',
        label: this.translations.general_statistics,
        component: Statistics,
        disabled: false,
      };
    },
    handleShowHelp() {
      if (this.isMob) {
        this.activeTab = {
          id: 2,
          key: 2,
          name: 'Help',
          label: this.translations.general_help,
          component: GameHelp,
          disabled: false,
        };
      }
    },
    toggleModal() {
      if (this.isMob) {
        this.showModal = !this.showModal;
      } else {
        this.showHelpModal = !this.showHelpModal;
      }
    },
    closeDetails(option) {
      if (option === 'outside') {
        this.showModal = false;
      } else {
        this.$refs.modal.closeModal('outside');
        setTimeout(() => {
          this.showModal = false;
        }, 500);
      }
      this.handleShowStats();
    },
    closeDetailsHelp(option) {
      if (option === 'outside') {
        this.showHelpModal = false;
      } else {
        this.$refs.modalHelp.closeModal('outside');
        setTimeout(() => {
          this.showHelpModal = false;
        }, 500);
      }
    },
    loadConfig() {
      return new Promise((resolve) => {
        // load config
        ConfigApi.get(this.config.company, this.config.channel, 'Web', this.lang).then((res) => {
          this.set_product_config(DataService.parseConfig(res.data.config));
          this.createSocketConnection();
          resolve();
        });
      });
    },
    checkForDisable() {
      if (this.selected_round.eventId <= this.active_round.eventId) {
        this.disable_betting(true);
      } else {
        this.disable_betting(false);
      }
    },
  },
  computed: {
    ...mapGetters([
      'config',
      'product_config',
      'round_phase',
      'active_round',
      'selected_round',
      'mobile',
      'loaded',
      'theme',
      'translations',
      'currentScheduleRound',
      'isBoostActive',
      'staticTheme',
    ]),
    isInfoButtonActive() {
      if (this.isResultsButtonActive && this.isStatsButtonActive) return false;

      return true;
    },
    isHelpButtonActive() {
      if (!this.isResultsButtonActive && !this.isStatsButtonActive) return false;

      return true;
    },
    isStatsButtonActive() {
      if ('showStatsIcon' in this.config.ui.config) {
        return this.config.ui.config.showStatsIcon;
      }
      return false;
    },
    isResultsButtonActive() {
      if ('showResultsIcon' in this.config.ui.config) {
        return this.config.ui.config.showResultsIcon;
      }
      return false;
    },
    productName() {
      return this.translations.pages_player_history_virtual_penalty_shootout;
    },
    roundLabel() {
      return this.translations.general_round;
    },
    ...mapGetters({
      isUserLoggedIn: 'user/isLoggedIn',
    }),
    isIntegration() {
      if (this.config.ui.config.mode
        && (this.config.ui.config.mode === 'integration'
        || this.config.ui.config.mode === 'standalone')) {
        return true;
      }
      return false;
    },
    isMobileDeviceIos() {
      return isMobile().apple.device;
    },
    components() {
      return [
        {
          id: 0,
          key: 0,
          name: 'Statistics',
          label: this.translations.general_statistics,
          component: Statistics,
          disabled: false,
        },
        {
          id: 1,
          key: 1,
          name: 'Results',
          label: this.translations.general_results,
          component: Results,
          disabled: false,
        },
      ];
    },
    component() {
      return this.activeTab;
    },
    isMob() {
      return isMobile().any || window.innerWidth <= 660;
    },
    isTablet() {
      return isMobile().tablet;
    },
    showBalance() {
      if ('showBalance' in this.config.ui.config) {
        return this.config.ui.config.showBalance;
      }
      return true;
    },
  },
  created() {
    const tabs = [
      {
        id: 0,
        name: 'Statistics',
        label: this.translations.general_statistics,
        component: Statistics,
        disabled: false,
      },
      {
        id: 1,
        name: 'Results',
        label: this.translations.general_results,
        component: Results,
        disabled: false,
      },
    ];
    const boostTab = {
      id: 0,
      key: 0,
      name: 'Activity',
      label: 'Activity',
      component: Boost,
      disabled: false,
    };
    const helpTab = {
      id: 2,
      key: 2,
      name: 'Help',
      label: this.translations.general_help,
      component: GameHelp,
      disabled: false,
    };
    if (!this.isMob && this.isBoostActive) {
      tabs.unshift(boostTab);
      tabs[1].id = 1;
      tabs[2].id = 2;
    }
    if (this.isMob) {
      tabs.push(helpTab);
    }
    if (this.isTablet) {
      tabs.unshift(boostTab);
      tabs[1].id = 1;
      tabs[2].id = 2;
    }
    this.tabComponents = tabs;
  },
  mounted() {
    eventBus.$on('ShowResults', this.handleShowResults);
    eventBus.$on('ShowStats', this.handleShowStats);
    eventBus.$on('ShowHelp', this.handleShowHelp);
  },
};

</script>

<style scoped lang="scss">
  ::v-deep .modal-back.help-container {
      .modal {
        max-width: 832px;
        background-color: var(--card);
        padding: 0;
        max-height: 80% !important;
        height: 100%;
        .modal-header {
          background-color: var(--button);
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 5px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          overflow-y: scroll;
          scrollbar-width: none;
          ::-webkit-scrollbar {
            width: 0 !important;
          }
          .tabs {
            .empty {
              width: 100%;
            }
          }
        }
        .modal-footer {
          display: none;
          padding-top: 0px;
        }
      }
    }
  ::v-deep .modal-back.events-container {
      width: 100%;
      margin: 0 auto;
      background-color: var(--overlay-lock);
      top: 0px;
      position: absolute;
      height: 100%;
      border-radius: 3px;
      .modal {
        max-width: 832px;
        position: absolute;
        top: 16px;
        background-color: var(--card);
        padding: 0;
        .modal-header {
          background-color: var(--button);
          height: 100px;
          .info {
            text-align: center;
            display: flex;
            flex-direction: column;
            position: relative;
            .product-info {
              color: var(--text-primary-1);
              font-size: 22px;
              line-height: 26px;
              font-weight: 500;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .round-info {
              color: var(--text-primary-1);
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
              font-weight: bold;
              user-select: none;
              -webkit-touch-callout: none;
              -webkit-user-select: none;
              -moz-user-select: none;
              -ms-user-select: none;
            }
            .icon {
              position: absolute;
              right: 16px;
              top: 19px;
              color: var(--text-primary-1);
              cursor: pointer;
              margin-left: auto;
              font-size: 18px;
            }
          }
        }
        .modal-body {
          overflow-y: scroll;
          scrollbar-width: none;
          ::-webkit-scrollbar {
            width: 0 !important;
          }
        }
        .modal-footer {
          display: none;
          padding-top: 0;
        }
      }
    }
  ::v-deep .betslip-footer.layout {
    display: unset !important;
  }
  ::v-deep .cancel-ticket {
    background-color: rgba(0, 0, 0, 0.7);
    .modal {
      .modal-header {
        padding: 0;
        text-align: left;
        height: 60px;
        h3 {
          font-size: 24px;
          margin: 0;
        }
      }
      .modal-body {
        padding: 0;
        text-align: left;
        font-size: 16px;
      }
      .modal-footer {
        height: 71px;
      }
    }
    button {
      font-family: Roboto !important;
    }
  }
  .main-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    ::-webkit-scrollbar {
      width: 0;
    }
    .content-left-wrapper {
      display: flex;
      //  max-width: 1184px;
      width: 100%;
      justify-content: center;
      .left-side-wrap {
        max-width: 1176px;
        width: 100%;
        background-color: var(--background-1);
        padding: 0 8px 8px 8px;
        .content-left {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .scrollable {
            .scroll-content {
              max-width: 1176px;
              &.full-web {
                max-height: 100vh;
                overflow-y: scroll;
                scrollbar-width: none;
              }
              .game-content {
                display: flex;
                .main {
                  position: relative;
                  width: 784px;
                }
                .sidebar {
                  width: 376px;
                  padding-left: 8px;
                  padding-top: 8px;
                  .component-wrapper {
                    display: flex;
                    height: 100%
                  }
                }
              }
            }
          }
        }
      }
    }
    .content-right {
      display: flex;
      width: 300px;
      justify-content: flex-end;
    }
  }
  .spacer-v {
    padding: 8px 0;
  }
  ::v-deep .tabs {
    .tab-button {
      max-width: unset;
    }
  }

@media (max-width: 640px) {
  .main-container {
    flex-direction: column;
    .content-left-wrapper {
      .left-side-wrap {
        max-width: unset;
        width: 100%;
        padding: 0;
        .content-left {
          .scrollable {
            .scroll-content {
              width: 100vw;
              .game-content {
                flex-direction: column;
                padding-bottom: 96px;
                .main {
                  width: 100%;
                  padding: 0;
                }
                .sidebar {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .content-right {
      margin: 0 auto;
      width: 641px;
      .betslip-area {
        width: 641px;
        left: 0;
        ::v-deep .betslipB {
          max-width: 641px;
        }
      }
    }
  }
  ::v-deep .modal-back.events-container {
    top: 0;
    .modal {
      top: 0;
    }
  }
}
@media (min-width: 641px) and (max-width: 959px) {
  .main-container {
    flex-direction: column;
    .content-left-wrapper {
      .left-side-wrap {
        max-width: 641px;
        .content-left {
          .scrollable {
            .scroll-content {
              .game-content {
                flex-direction: column;
                padding-bottom: 96px;
                .main {
                  width: 625px;
                }
                .sidebar {
                  width: 625px;
                  padding: 0;
                }
              }
            }
          }
        }
      }

    }
    .content-right {
      margin: 0 auto;
      width: 641px;
      .betslip-area {
        width: 641px;
        left: 0;
        ::v-deep .betslipB {
          max-width: 641px;
        }
      }
    }
  }
  ::v-deep .modal-back.events-container {
    top: 0;
    .modal {
      top: 0;
    }
  }
}
@media (min-width: 960px) and (max-width: 1267px) {
  .main-container {
    .content-left-wrapper {
      .left-side-wrap {
        max-width: 676px;
        .content-left {
          .scrollable {
            .scroll-content {
              .game-content {
                flex-direction: column;
                .main {
                  width: 660px;
                }
                .sidebar {
                  width: 660px;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (min-width: 1268px) and (max-width: 1468px) {
  .main-container {
    .content-left-wrapper {
      .left-side-wrap {
        max-width: 960px;
        .content-left {
          .scrollable {
            .scroll-content {
              .game-content {
                .main {
                  width: 640px;
                }
                .sidebar {
                  width: 304px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
